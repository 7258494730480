import React, { useEffect, useState } from "react";
import styles from './ConfirmationModal.module.scss';
import { ThemeProvider, Modal, ButtonGroup } from "@vacasa/react-components-lib";
import _default from "@vacasa/react-components-lib/lib/themes/default";
import _ from "lodash";
import { authManager } from '@common/authentication';
import { UnitBaseFieldService } from "@common/units-api";
import {
  UnitBaseFields,
  UnitBaseFieldsByUnit,
  UnitBaseFieldsByUnitRequest
} from "@common/typing";
import { useToasts } from "react-toast-notifications";

interface ConfirmationModal { }

export const ConfirmationModalComponent: React.FunctionComponent<ConfirmationModal> = (props) => {
  const [isLoading, setIsLoading] = useState(false);
  const [showModal, setShowModal] = useState(true);
  const [currentMinRates, setCurrentMinRates] = useState({
    vacasa: { value: null, id: null },
    owner: { value: null, id: null },
    hoa: { value: null, id: null },
  });
  const [uuid, setUuid] = useState<string>("");
  const { addToast } = useToasts();
  const unitService = new UnitBaseFieldService(authManager.getJwt());
  const info = authManager.getInfoFromAdmin<{ user: string; unitId: number, canEdit: { vacasa: boolean, owner: boolean, hoa: boolean }, unitCurrency?: string }>();

  const loadMinRateValues = async (unitUUID: string) => {
    try {
      const unitBaseFieldsResponse: Array<UnitBaseFields> = await unitService.getUnitBaseFieldsValues(unitUUID, "min_rate");

      if (!_.isNil(unitBaseFieldsResponse)) {
        const newCurrentMinRates = { ...currentMinRates };

        for (const v of unitBaseFieldsResponse) {
          if (v.attributes.base_field === "min_rate") {
            newCurrentMinRates[v.attributes?.stakeholder] = {
              value: v.attributes?.value,
              id: v.attributes.id,
            };
          }
        }
        setCurrentMinRates(newCurrentMinRates);
      }
    } catch (e) {
      addToast(`Error loading Unit Min Rate information`, { appearance: "error" });
      setIsLoading(false);
    }
  };

  useEffect(() => {
    (async () => {
      try {
        setIsLoading(true);
        const unitUUID = await unitService.getUuidByLegacyUnitIdV2(info.unitId);
        setUuid(unitUUID);
        await loadMinRateValues(unitUUID);
      } catch (e) {
        addToast(`Error loading Unit Min Rate information`, { appearance: "error" });
      } finally {
        setIsLoading(false);
      }
    })();
  }, []);

  const reloadContent = () => {
    window.parent.postMessage({action: "click", type: "reloadContent"}, "*");
  };

  const closeModal = () => {
    setShowModal(!showModal);
    window.parent.postMessage({action: "click", type: "closeModal"}, "*");
  };

  const createDataToSave = (stakeholder: string, action: string, clear: boolean = false,) => {
    let value = currentMinRates[stakeholder].value === "" ? null : currentMinRates[stakeholder].value;
    value = clear ? null : value;
    const id = currentMinRates[stakeholder].id;
    return {
      type: "unit_base_field_by_unit",
      id: id,
      attributes: {
        action: action,
        unit_id: uuid,
        base_field: "min_rate",
        stakeholder: stakeholder,
        value: value,
        done_by: info["user"],
      },
    } as UnitBaseFieldsByUnit;
  };

  const clear = async () => {
    setIsLoading(true);
    const dataRequest = { data: [] } as UnitBaseFieldsByUnitRequest;

    if (_.isNil(currentMinRates.vacasa.id) && _.isNil(currentMinRates.hoa.id) && _.isNil(currentMinRates.owner.id)) {
      dataRequest.data.push(createDataToSave("vacasa", "create"));
    } else {
      if (currentMinRates.vacasa.id) {
        dataRequest.data.push(createDataToSave("vacasa", "update", true));
      }
      if (currentMinRates.owner.id) {
        dataRequest.data.push(createDataToSave("owner", "update", true));
      }
      if (currentMinRates.hoa.id) {
        dataRequest.data.push(createDataToSave("hoa","update", true));
      }
    }

    try {
      const promises = [];
      promises.push(unitService.processUnitBaseFields(dataRequest));

      await Promise.all(promises);

      addToast("Min Rate values cleared. Refresh the page to see sync changes", { appearance: "success" });
    } catch (e) {
      console.log(e);
      addToast(`Error clearing Min Rate values: ${e.detail?.error}`, { appearance: "error" });
    } finally {
      setIsLoading(false);
      closeModal();
      reloadContent();
    }
  };

  const leftButton = {
    variant: "secondary",
    onClick: clear,
    children: "Confirm",
    customClass: `${styles.unitBaseFieldClearModalButton}`,
  };

  const rightButton = {
    variant: "secondary",
    onClick: closeModal,
    children: "Cancel",
    customClass: `${styles.unitBaseFieldClearModalButton}`,
  };

  const renderModal = () => {
    return (
      <ThemeProvider theme={_default}>
        <Modal showModal={showModal} setShowModal={setShowModal} size="medium" canExit={false}>
          <div>
            <h2> Clear All STR Min Rates </h2>
            <div className={`${styles.modalText}`}>
              Are you sure you want to delete all STR Min rates for this unit?
            </div>
            <div className={`${styles.buttonContainer}`}>
              {isLoading ? (
                <div className={`col ${styles.centerContent}`}>
                  <h6>Loading buttons...</h6>
                </div>
              ) : (
                <ButtonGroup left={leftButton} right={rightButton}></ButtonGroup>
              )}
            </div>
          </div>
        </Modal>
      </ThemeProvider>
    );
  };

  return (
    <div className={`${styles.summaryApp}`}>
      <React.Fragment>
        {renderModal()}
      </React.Fragment>
    </div>
  );
};
