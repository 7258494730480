import React, { useEffect, useState } from "react";
import styles from './Content.module.scss';
import { BorderContainer } from '@common/react-components';
import {
  Input,
  ThemeProvider,
  Button,
  AlertMessage,
  Tooltip, Icon
} from "@vacasa/react-components-lib";
import _default from "@vacasa/react-components-lib/lib/themes/default";
import _ from "lodash";
import { authManager } from '@common/authentication';
import { UnitBaseFieldService } from "@common/units-api";
import {
  UnitBaseFields,
  UnitBaseFieldsByUnit,
  UnitBaseFieldsByUnitRequest,
} from "@common/typing";
import { useToasts } from "react-toast-notifications";
import { MinRateFields } from "./MinRateFields";
import { LTRMinRateFields } from "./LTRMinRateFields";
import {Configuration} from "@common/configuration"

interface ContentProps {}

export const Content: React.FunctionComponent<ContentProps> = (_props) => {
  const MIN_RATE_CODE = "min_rate";
  const LTR_MIN_RATE_CODE = "LTR_min_rate";
  const [isLoading, setIsLoading] = useState(false);
  const [hasSeasonalRates, setHasSeasonalRates] = useState(false);
  const [minRates, setMinRates] = useState({
    vacasa: null,
    owner: null,
    hoa: null,
  });
  const [currentMinRates, setCurrentMinRates] = useState({
    vacasa: { value: null, id: null },
    owner: { value: null, id: null },
    hoa: { value: null, id: null },
  });
  const [LTRMinRates, setLTRMinRates] = useState({
    vacasa: null,
    owner: null,
    hoa: null,
  });
  const [currentLTRMinRates, setCurrentLTRMinRates] = useState({
    vacasa: { value: null, id: null },
    owner: { value: null, id: null },
    hoa: { value: null, id: null },
  });
  const [uuid, setUuid] = useState<string>("");
  const [active, setActive] = useState<string>();
  const [disableFields, setDisableFields] = useState({
    vacasa: false,
    owner: false,
    hoa: false
  });
  const [disableButton, setDisableButton] = useState(true);
  const { addToast } = useToasts();
  const unitService = new UnitBaseFieldService(authManager.getJwt());
  const info = authManager.getInfoFromAdmin<{ user: string; unitId: number; canEdit: { vacasa: boolean, owner: boolean, hoa: boolean}; unitCurrency?: string }>();
  const LTRFeatureFlagKey = "admin_ui_unit_LTR_min_rate";

  const loadRateValues = async (unitUUID: string) => {
    try {
      const unitBaseFieldsResponse: Array<UnitBaseFields> = await unitService.getUnitBaseFieldsValues(unitUUID, `${MIN_RATE_CODE},${LTR_MIN_RATE_CODE}`);

      if (!_.isNil(unitBaseFieldsResponse)) {
        const newMinRates = { ...minRates };
        const newCurrentMinRates = { ...currentMinRates };

        const newLTRMinRates = { ...LTRMinRates };
        const newCurrentLTRMinRates = { ...currentLTRMinRates };

        for (const v of unitBaseFieldsResponse) {
          if (v.attributes.base_field === MIN_RATE_CODE) {
            if (v.attributes?.active) setActive(v.attributes?.stakeholder);

            newMinRates[v.attributes?.stakeholder] = v.attributes?.value;
            newCurrentMinRates[v.attributes?.stakeholder] = {
              value: v.attributes?.value,
              id: v.attributes.id,
            };
          } else if (v.attributes.base_field === LTR_MIN_RATE_CODE) {
            newLTRMinRates[v.attributes?.stakeholder] = v.attributes?.value;
            newCurrentLTRMinRates[v.attributes?.stakeholder] = {
              value: v.attributes?.value,
              id: v.attributes.id,
            };
          }
        }

        setMinRates(newMinRates);
        setCurrentMinRates(newCurrentMinRates);
        setLTRMinRates(newLTRMinRates);
        setCurrentLTRMinRates(newCurrentLTRMinRates);
      }
    } catch (e) {
      addToast(`Error loading Unit Min Rate information`, { appearance: "error" });
      setIsLoading(false);
    }
  };

  useEffect(() => {
    (async () => {
      try {
        setIsLoading(true);
        const unitUUID = await unitService.getUuidByLegacyUnitIdV2(info.unitId);
        setUuid(unitUUID);
        await loadRateValues(unitUUID);
        setDisableFields({
          vacasa: !info['canEdit']['vacasa'],
          owner: !info['canEdit']['owner'],
          hoa: !info['canEdit']['hoa'],
        });
        //if user has at least one permission, the buttons must be enabled.
        for (const stakeholder in info['canEdit']) {
          if (info['canEdit'][stakeholder] === true) setDisableButton(false);
        }
        if(_.isNil(unitUUID)) return
        try {
          const unitSeasonalRates = await unitService.getUnitRules({
            "filter[code]" : "SEASONAL-RATES",
            "filter[uuid]" : unitUUID,
            "filter[is_active]" : true
          });
          if(_.isArray(unitSeasonalRates) && unitSeasonalRates.length > 0) setHasSeasonalRates(true)
          else setHasSeasonalRates(false)
        } catch (error) {
          addToast(`Error while loading Unit Seasonal Rates information! Be aware, if a seasonal rates rule exists for this unit, it can result in different min rates applying for this unit`, { appearance: "error" });
        }
      } catch (e) {
        addToast(`Error loading Unit Min Rate information`, { appearance: "error" });
      } finally {
        setIsLoading(false);
      }
    })();
  }, []);

  useEffect(() => {
    let height = document.getElementById('root')?.clientHeight;
    height = height - 0.5;
    window.parent.postMessage({action: `${height}px`, type: 'resize'}, '*');
  } );

  const onlyPositiveNumberValidation = () => {
    const checkAndNotify = (value: string | null, name: string) => {
      
      if (!_.isNil(value)) {
        if(!(/^[0-9]\d*$/.test(value || ""))){ //positive integer number
            addToast(`${name} value should be a positive integer`, { appearance: "error" });
            setIsLoading(false);
            return false;
        }
      }
      return true;
    };

    return (
      checkAndNotify(minRates.vacasa, "Vacasa STR Min Rate") &&
      checkAndNotify(minRates.owner, "Owner STR Min Rate") &&
      checkAndNotify(minRates.hoa, "HOA STR Min Rate") &&
      checkAndNotify(LTRMinRates.vacasa, "Vacasa LTR Min Rate") &&
      checkAndNotify(LTRMinRates.owner, "Owner LTR Min Rate") &&
      checkAndNotify(LTRMinRates.hoa, "HOA LTR Min Rate")
    );
  };

  const createDataToSave = (baseField: string, stakeholder: string, value: string, action: string, id: number) => {
    return {
      type: "unit_base_field_by_unit",
      id: id,
      attributes: {
        action: action,
        unit_id: uuid,
        base_field: baseField,
        stakeholder: stakeholder,
        value: value,
        done_by: info["user"],
      },
    } as UnitBaseFieldsByUnit;
  };

  /**
   * Compare de data and decide if needs create or update
   * 1 = create
   * 2 = update
   * @param baseField
   * @param stakeholder
   */
  const compareData = (baseField: string, stakeholder: string) => {
    let inputBaseField = {}, currentBaseField = {};
    switch (baseField) {
      case MIN_RATE_CODE: {
        inputBaseField = minRates;
        currentBaseField = currentMinRates;
        break;
      }
      case LTR_MIN_RATE_CODE: {
        inputBaseField = LTRMinRates;
        currentBaseField = currentLTRMinRates;
        break;
      }
    }

    if(inputBaseField[stakeholder] !== currentBaseField[stakeholder].value) {
      if (_.isNil(currentBaseField[stakeholder].id)) {
        return 1;
      } else {
        return 2;
      }
    }
  }

  const save = async () => {
    setIsLoading(true);
    const dataRequest = { data: [] } as UnitBaseFieldsByUnitRequest;


    if (!onlyPositiveNumberValidation()) return;
    let minRateChanges = 0;

    for (const stakeholder in minRates) {
      const action = compareData(MIN_RATE_CODE, stakeholder);
      const value = minRates[stakeholder] === "" ? null : minRates[stakeholder];
      const id = currentMinRates[stakeholder].id;
      if (action !== undefined) {
        const method = action === 1 ? "create" : "update";
        dataRequest.data.push(createDataToSave(MIN_RATE_CODE, stakeholder, value, method, id));
        minRateChanges++;
      }
    }

    for (const stakeholder in LTRMinRates) {
      const action = compareData(LTR_MIN_RATE_CODE, stakeholder);
      const value = LTRMinRates[stakeholder] === "" ? null : LTRMinRates[stakeholder];
      const id = currentLTRMinRates[stakeholder].id;
      if (action !== undefined) {
        const method = action === 1 ? "create" : "update";
        dataRequest.data.push(createDataToSave(LTR_MIN_RATE_CODE, stakeholder, value, method, id));
      }
    }

    if (dataRequest.data.length > 0 ) {
      try {
        const promises = [];
        promises.push(unitService.processUnitBaseFields(dataRequest));

        await Promise.all(promises);
        loadRateValues(uuid);
        if (minRateChanges > 0)
          addToast("Values saved. Refresh the page to see sync Min Rate changes", { appearance: "success" });
        else
          addToast("Values saved.", { appearance: "success"});
      } catch (e) {
        let errorMessage = e.response.data[0].detail?.error;
        if (typeof e.response.data[0].detail?.error === "object") {
          if (e.response.data[0].detail?.error.details === "jwt expired")
            errorMessage = "Token Expired. Please refresh de page and save the data again."
        }

        addToast(`Error saving Rates values: ${errorMessage}`, { appearance: "error" });
      } finally {
        setIsLoading(false);
      }
    } else {
      addToast("No changes to save", { appearance: "warning" });
      setIsLoading(false);
    }
  };

  const renderInput = (label: string, value: string | null, tooltip: string, onChange: (value: string | null) => void, disabled: boolean = false, required: React.ReactNode = null) => {
    return (
      <div className="col-md-3">
        <ThemeProvider theme={_default}>
            <Tooltip message={tooltip} placement={"top"}>
              <span className={`${styles.labelInput}`}>{label} {required}</span>
            </Tooltip>
            <Input
              type="text"
              label={""}
              value={value || ""}
              decoration={info['unitCurrency'] ?? ""}
              onChange={(e) => onChange(e.target.value === "" ? null : e.target.value)}
              disabled={disabled}
              customClass={`${styles.unitBaseFieldInput}`}
            />
        </ThemeProvider>
      </div>
    );
  };


  const handleSeasonalRateWarningClick = () => {
    window.parent.postMessage({action: 'crossSpaCommand', type: 'showAndScrollToRulesShelf'}, '*');
  }

  const applyConfigButton = {
    variant: "secondary",
    onClick: save,
    children: "Apply",
    disabled: disableButton,
    customClass: `${styles.unitBaseFieldButton}`,
  };

  const applyButton = <Button variant={applyConfigButton.variant} onClick={applyConfigButton.onClick} children={applyConfigButton.children} disabled={applyConfigButton.disabled} customClass={applyConfigButton.customClass}/>

  const SeasonalRatesWarningTextRow = () => {
    return hasSeasonalRates ? (
        <div className="form-row">
          <div className={`col-md-12 ${styles.warningText}`}>
            <ThemeProvider theme={_default}>
              <AlertMessage type="warning">This unit has a <strong>Seasonal Min Rate rule</strong>, which can result in different min rates applying for this unit.<Button onClick={handleSeasonalRateWarningClick} variant="info" size="small" customClass={`${styles.viewRulesButton}`}>View rules</Button></AlertMessage>
            </ThemeProvider>
          </div>
        </div>
      ) : (<React.Fragment></React.Fragment>)
  }

  const minRateContent = (shouldRenderLTR = true) => (<div>
    {SeasonalRatesWarningTextRow()}
    <MinRateFields disableFields={disableFields} minRates={minRates} setMinRates={setMinRates} LTRisEnable={shouldRenderLTR} active={active} renderInput={renderInput} disableButton={disableButton} applyButton={applyConfigButton} />
    { shouldRenderLTR ? (<LTRMinRateFields disableFields={disableFields} LTRMinRates={LTRMinRates} setLTRMinRates={setLTRMinRates} renderInput={renderInput} applyButton={applyButton} />) : (<React.Fragment></React.Fragment>)}
  </div>)
  const container = Configuration.environment() !== Configuration.LOCAL ? minRateContent(true) : minRateContent() ;
  return (
    <div className={`${styles.summaryApp}`}>
      {isLoading ? (
        <div className="row">
          <div className={`col ${styles.centerContent}`}>
            <h6>Loading data...</h6>
          </div>
        </div>
      ) : (
        <React.Fragment>
          <div className="container-fluid">
            <BorderContainer content={container} />
          </div>
        </React.Fragment>
      )}
    </div>
  );
};
