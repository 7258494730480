import React from "react";
import {BrowserRouter as Router, Switch, Route} from "react-router-dom";
import {Content} from "../components/content/Content";
import {ConfirmationModalComponent} from "../components/confirmationModal/ConfirmationModal";

class RouterApp extends React.Component {
    render() {
      return (
        <Router>
          <Switch>
            <Route path="/confirm" component={ConfirmationModalComponent} />
            <Route path="/" component={Content} />
          </Switch>
        </Router>
        );
    }
}

export default RouterApp;
