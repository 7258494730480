import React, { useEffect } from "react";
import { ThemeProvider } from "@vacasa/react-components-lib";
import _default from "@vacasa/react-components-lib/lib/themes/default";
import styles from "./Content.module.scss";

interface LTRMinRateFieldsProps {
  disableFields: { vacasa: boolean, owner: boolean, hoa: boolean };
  LTRMinRates: any;
  setLTRMinRates: Function;
  renderInput: Function;
  applyButton: any;
}

export const LTRMinRateFields: React.FunctionComponent<LTRMinRateFieldsProps> = (props) => {
  const {disableFields, LTRMinRates, setLTRMinRates, renderInput, applyButton } = props;

  const disabledVacasaField = disableFields['vacasa'];
  const disabledOwnerField = disableFields['owner'];
  const disabledHOAField = disableFields['hoa'];

  useEffect(() => {
    let height = document.getElementById('root')?.clientHeight;
    height = height - 0.5;
    window.parent.postMessage({action: `${height}px`, type: 'resize'}, '*');
  } );

  const LTRMinRateButtons = () => {
    return <div className={`col-md-3 ${styles.buttonContainer}`}>
      <ThemeProvider theme={_default}>
        {applyButton}
      </ThemeProvider>
    </div>
  }

  return (
    <div className="form-row">
      {renderInput("Vacasa LTR Min Rate ", LTRMinRates.vacasa, "Long Term Minrate", (value: string) => setLTRMinRates({ ...LTRMinRates, vacasa: value }), disabledVacasaField)}
      {renderInput("Owner LTR Min Rate ", LTRMinRates.owner, "Long Term Minrate", (value: string) => setLTRMinRates({ ...LTRMinRates, owner: value }), disabledOwnerField)}
      {renderInput("HOA  LTR Min Rate ", LTRMinRates.hoa, "Long Term Minrate", (value: string) => setLTRMinRates({ ...LTRMinRates, hoa: value }), disabledHOAField)}
      {LTRMinRateButtons()}
    </div>
  )
}
