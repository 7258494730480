import React, { useEffect } from "react";
import styles from "./Content.module.scss";
import { Button, ButtonGroup, Icon, ThemeProvider, Tooltip } from "@vacasa/react-components-lib";
import _default from "@vacasa/react-components-lib/lib/themes/default";

interface MinRateFieldsProps {
  disableFields: { vacasa: boolean, owner: boolean, hoa: boolean };
  minRates: any;
  setMinRates: Function;
  LTRisEnable: boolean;
  active: string;
  renderInput: Function;
  disableButton: boolean;
  applyButton: object;
}

export const MinRateFields: React.FunctionComponent<MinRateFieldsProps> = (props) => {
  const {disableFields, minRates, setMinRates, LTRisEnable, active, renderInput, disableButton, applyButton } = props;
  const disabledVacasaField = disableFields['vacasa'];
  const disabledOwnerField = disableFields['owner'];
  const disabledHOAField = disableFields['hoa'];

  useEffect(() => {
    let height = document.getElementById('root')?.clientHeight;
    height = height - 0.5;
    window.parent.postMessage({action: `${height}px`, type: 'resize'}, '*');
  } );

  const activeIcon = <ThemeProvider theme={_default}>
      <Tooltip message={"Applied Min Rate"} placement={"right"}>
        <span><Icon.CheckCircle height={12} width={12} color={"#00a433"}/></span>
      </Tooltip>
    </ThemeProvider>;

  const clearConfirmationModal = () => {
    window.parent.postMessage({action: 'click', type: 'showShelfMinRate'}, '*');
  };

  const clearButton = {
    variant: "secondary",
    onClick: clearConfirmationModal,
    children: "Clear All STR Min Rates",
    disabled: disableButton,
    customClass: styles.unitBaseFieldButton,
  };

  const minRateButtons = (LTRisEnable: boolean = false) => {
    return <div className={`col-md-3 ${styles.buttonContainer}`}>
      <ThemeProvider theme={_default}>
        {
          LTRisEnable ? (
            <Button variant={clearButton.variant} onClick={clearButton.onClick} children={clearButton.children} disabled={clearButton.disabled} customClass={clearButton.customClass}/>
          ) : (
            <ButtonGroup left={applyButton} right={clearButton}></ButtonGroup>
          )
        }
      </ThemeProvider>
    </div>
  }

  return(
   <div className={`form-row ${styles.customRow}`}>
     {renderInput("Vacasa STR Min Rate ", minRates.vacasa, "Short Term Minrate", (value: string) => setMinRates({ ...minRates, vacasa: value }), disabledVacasaField, active === "vacasa" ? activeIcon : null)}
     {renderInput("Owner STR Min Rate ", minRates.owner, "Short Term Minrate", (value: string) => setMinRates({ ...minRates, owner: value }), disabledOwnerField, active === "owner" ? activeIcon : null)}
     {renderInput("HOA STR Min Rate ", minRates.hoa, "Short Term Minrate", (value: string) => setMinRates({ ...minRates, hoa: value }), disabledHOAField, active === "hoa" ? activeIcon : null)}
     {minRateButtons(LTRisEnable)}
   </div>
  )

}
