import React from "react";
import "./App.css";
import RouterApp from "./router/Router";
import {ToastProvider} from "react-toast-notifications";

function App() {
    return (
        <div className="App">
          <ToastProvider autoDismiss autoDismissTimeout={5000} placement="bottom-right">
            <RouterApp/>
          </ToastProvider>
        </div>
    );
}

export default App;